import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { Charting } from '~/pages/heineken_template/_private/Charting';
import { store } from '~/pages/heineken_template/_private/store';
import { sungop_initStyling } from '~/pages/sungop/_private/sungop_initStyling';
import { Sungop_PageMenu } from '~/pages/sungop/_private/sungop_PageMenu';
import { Sungop_Sidebar } from '~/pages/sungop/_private/sungop_Sidebar';
import { Sungop_DailyChips } from './sungop_DailyChips';
import { Sungop_stockMonitor } from './sungop_stockMonitor';
import { Sungop_OptionState } from './sunop_OptionState';
import { sungop_initStrategies } from './sungop_initStrategies';
import { sungop_OptionChips } from './sungop_OptionChips';
import OpbsProvider from '~/pages/futuresai/opbs/containers/OpbsProvider';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
import { Sungop_Topbar } from './sungop_Topbar';
import dayAPI from '~/utils/dayAPI';
import { Sungop_SiderbarStockFutures } from './sungop_SiderbarStockFutures';
export const sungop_init = {
    global(templateProps) {
        sungop_initStyling(templateProps);
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.layout.Charting = undefined;
        templateProps.layout.Drawer1 = Sungop_PageMenu;
        templateProps.layout.Row1 = Sungop_Topbar;
        store.charting.calcFrom = dayAPI().subtract(5, 'day');
    },
    indexPage(templateProps) {
        sungop_init.global(templateProps);
        sungop_initStrategies();
        templateProps.layout.Row1 = <Sungop_Topbar rightBurger={true}/>;
        templateProps.layout.Charting = Charting;
        templateProps.layout.Drawer2 = Sungop_Sidebar;
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 200px 336px 1fr;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Drawer2 Chart'
        'Drawer1 Drawer2 Chart';

      overflow: auto;
      ${createIPadCss(css `
        place-content: flex-start;
        grid-template-rows: 48px 1fr calc(100% - 48px);
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Chart Chart Chart';
      `)}

      ${createMobileCss2(css `
        place-content: flex-start;
        grid-template-rows: 48px 1fr calc(100% - 48px);
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Chart Chart Chart';
      `)}
    `;
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            symbol: 'TX-1',
            interval: '5',
        };
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['monitors/stock/index.page'](templateProps) {
        sungop_init.global(templateProps);
        templateProps.layout.Col2 = Sungop_stockMonitor;
        templateProps.layout.cssset = css `
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 200px 1fr;
      overflow: auto;

      grid-template-areas:
        'Drawer1 Col2'
        'Drawer1 Col2';

      ${createIPadCss(css `
        place-content: flex-start;
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2';
      `)}

      ${createMobileCss2(css `
        place-content: flex-start;
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2';
      `)}
    `;
    },
    ['stock-monitor-table/index.page'](templateProps) {
        sungop_init.global(templateProps);
        templateProps.layout.Col2 = Sungop_stockMonitor;
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 200px 1fr;
      overflow: auto;

      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Col2 Col2'
        'Drawer1 Col2 Col2';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2'
          'Col2 Col2';
      `)}
    `;
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['daily-chips/index.page'](templateProps) {
        sungop_init.global(templateProps);
        templateProps.layout.Col2 = Sungop_DailyChips;
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 200px 1fr;
      overflow: auto;

      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Col2 Col2'
        'Drawer1 Col2 Col2';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2'
          'Col2 Col2';
      `)}
    `;
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['option-chips/index.page'](templateProps) {
        sungop_init.global(templateProps);
        templateProps.layout.Providers = OpbsProvider;
        templateProps.layout.Col2 = sungop_OptionChips;
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 200px 1fr;
      overflow: auto;

      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Col2 Col2'
        'Drawer1 Col2 Col2';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2'
          'Col2 Col2';
      `)}
    `;
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['option-state/index.page'](templateProps) {
        sungop_init.global(templateProps);
        templateProps.layout.Col2 = Sungop_OptionState;
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 200px 1fr;
      overflow: auto;

      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Col2 Col2'
        'Drawer1 Col2 Col2';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2'
          'Col2 Col2';
      `)}
    `;
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['stock-futures/index.page'](templateProps) {
        sungop_init.global(templateProps);
        sungop_initStrategies();
        templateProps.layout.Row1 = <Sungop_Topbar rightBurger={true}/>;
        templateProps.layout.Charting = Charting;
        templateProps.layout.Drawer2 = Sungop_SiderbarStockFutures;
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 200px 336px 1fr;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Drawer2 Chart'
        'Drawer1 Drawer2 Chart';

      overflow: auto;
      ${createIPadCss(css `
        place-content: flex-start;
        grid-template-rows: 48px 1fr calc(100% - 48px);
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Chart Chart Chart';
      `)}

      ${createMobileCss2(css `
        place-content: flex-start;
        grid-template-rows: 48px 1fr calc(100% - 48px);
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Chart Chart Chart';
      `)}
    `;
        templateProps.hooks.add(useSignalrStart2_0);
    },
};
