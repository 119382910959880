import { proxy } from 'valtio';
export const sungop_store = proxy({
    sidebarType: 'quote',
    dataDate: '',
    menuSymbolMode: 'index',
    sidebarSymbols1: [
        'TX-1',
        'TSEA',
        'OTCA',
        'TSE13',
        'TSE17',
        '2330',
        '2317',
        '2454',
        '6505',
        '2308',
        '2881',
        '2882',
    ],
});
