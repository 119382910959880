import styled from '@emotion/styled';
import { memo } from 'react-tracked';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import IntradayTrendChart from '~/modules/trendChart';
import { store } from '~/pages/heineken_template/_private/store';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { QuoteCard } from './component/QuoteCard';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useEffect } from 'react';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useMount } from 'react-use';
import { sungop_strategiesGroup } from './sungop_initStrategies';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import { SwitchSortButton } from '~/pages/freeman/freeman_SidePane1';
import { useVirtualAccountHolding } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { useStkfutDailyRankResource, } from '~/modules/screener/containers/useStockScreenerResource';
const stkRankEmptyData = [
    {
        date: '',
        datetime: '',
        symbol: '',
        open: 0,
        high: 0,
        low: 0,
        close: 0,
        volume: 0,
        last_close: 0,
        last_volume: 0,
        change: 0,
        change_percent: 0,
        amplitude: 0,
        amount: 0,
    },
];
export const Sungop_SiderbarStockFutures = memo(function Sungop_SiderbarStockFutures() {
    const charting = useSnapshot(store.charting);
    const stockFutures = useSnapshot(staticStore).symbol.stockFuturesMapper;
    const stockFuturesSymbol = Object.keys(stockFutures ?? []);
    const futuresDisctionary = useSnapshot(staticStore.symbol.stockFuturesMapper);
    const day0 = useSnapshot(staticStore).tradedDate.day0;
    const stkRankData = useStkfutDailyRankResource({
        date: day0.format('YYYY-MM-DD'),
        sort_by: 'volume',
        order_by: 'desc',
        volume_gte: 50,
        volume_lte: 10000000,
        amount_gte: 0,
        amount_lte: 0,
        limit: 150,
    }, 1).data ?? stkRankEmptyData;
    const symbolEntryLong = useVirtualAccountHolding('sungop_long');
    const symbolEntryShort = useVirtualAccountHolding('sungop_short');
    const stkLongSymbol = symbolEntryLong
        ?.filter(item1 => stockFuturesSymbol.some(symbol => symbol === item1.symbol))
        ?.map(s => futuresDisctionary[s.symbol] + '-1') ?? [''];
    const stkShortSymbol = symbolEntryShort
        ?.filter(item1 => stockFuturesSymbol.some(symbol => symbol === item1.symbol))
        .map(s => futuresDisctionary[s.symbol] + '-1') ?? [''];
    const resultLongSymbol = stkLongSymbol
        ?.filter(item1 => stkRankData.some(symbol => symbol.symbol === item1))
        .slice(0, 10);
    const resultShortSymbol = stkShortSymbol
        ?.filter(item1 => stkRankData.some(symbol => symbol.symbol === item1))
        .slice(0, 10);
    //
    useEffect(() => {
        signalrStore2.addQuote(resultLongSymbol.concat(resultShortSymbol));
        return () => {
            signalrStore2.removeQuote(resultLongSymbol.concat(resultShortSymbol));
        };
    }, [JSON.stringify(resultLongSymbol.concat(resultShortSymbol))]);
    const valueLong = signalrHooks2.useQuotes(useSignalr2QuoteSort(resultLongSymbol ?? ['']));
    const valueShort = signalrHooks2.useQuotes(useSignalr2QuoteSort(resultShortSymbol ?? ['']));
    useMount(() => {
        useSortSignalrDataStore.sortKey = 'volume';
        store.charting.setStrategy(sungop_strategiesGroup.main[4]);
        store.charting.updateFromStrategyState();
    });
    return (<styleds.container>
      <styleds.trendChartContnet>
        <TitleSymbolQuote.Default symbol={charting.symbol}/>
        <styleds.trendChart>
          <IntradayTrendChart symbol={charting.symbol} ticksSize={12} ticksHeight={20} priceTicksMargin={-10} priceTicksSize={11}/>
        </styleds.trendChart>
      </styleds.trendChartContnet>
      <styleds.sortContent>
        <span>排序方式: </span>
        <SwitchSortButton sortStore={useSortSignalrDataStore} sortKey='changePrecent'>
          漲跌幅
        </SwitchSortButton>
        <SwitchSortButton sortStore={useSortSignalrDataStore} sortKey='volume'>
          成交量
        </SwitchSortButton>
        <SwitchSortButton sortStore={useSortSignalrDataStore} sortKey='close'>
          價格
        </SwitchSortButton>
      </styleds.sortContent>
      <styleds.symbolTitle color='#aa3333'>日線多方格局 {valueLong?.length}檔</styleds.symbolTitle>
      <styleds.symbolList>
        {valueLong.map(s => {
            return (<styleds.symbolItem key={s.symbol}>
              <QuoteCard symbol={s.symbol}/>
            </styleds.symbolItem>);
        })}
      </styleds.symbolList>
      <styleds.symbolTitle color='#33aa33'>日線空方格局 {valueShort?.length}檔</styleds.symbolTitle>
      <styleds.symbolList>
        {valueShort.map(s => {
            return (<styleds.symbolItem key={s.symbol}>
              <QuoteCard symbol={s.symbol}/>
            </styleds.symbolItem>);
        })}
      </styleds.symbolList>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #181a21;
    gap: 4px;
    padding: 8px;
  `,
    trendChartContnet: styled.div `
    ${fill_vertical_all_center};
    width: 100%;
    height: 160px;
    border-radius: 7px;
    background-color: #2d303eaa;
    padding: 6px;
  `,
    trendChart: styled.div `
    width: 100%;
    height: 132px;
  `,
    symbolList: styled.div `
    ${scrollbar2Css};
    height: calc(50% - 128px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    padding: 2px;
    align-content: flex-start;
  `,
    symbolItem: styled.div `
    flex: 1 0 calc(50% - 4px);
    height: 102px;
    border-radius: 6px;
    background-color: #1f222b;
    max-width: 50%;
  `,
    sortContent: styled.div `
    ${fill_horizontal_cross_center};
    gap: 12px;
    height: 32px;
    background-color: #23262f;
    padding: 0 4px;
    border-radius: 4px;
    user-select: none;
    font-size: 14px;
    & > div {
      ${fill_horizontal_cross_center};
      width: auto;
      font-size: 14px;
    }
  `,
    symbolTitle: styled.div `
    ${fill_horizontal_all_center};
    height: 24px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    background-color: ${props => props.color};
  `,
};
