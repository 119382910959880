//股票升降跳動要顯示的小數位
export const stockDecimal = (value) => {
    if (value < 100) {
        return 2;
    }
    else if (value >= 100 && value < 1000) {
        return 1;
    }
    else if (value >= 1000) {
        return 0;
    }
    else
        return 2;
};
