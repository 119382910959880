import { store } from '~/pages/heineken_template/_private/store';
import { op_daytrade_signal } from '~/trades/indicators/sungop/op_daytrade_signal';
import { op_daytrade_signal_os } from '~/trades/indicators/sungop/op_daytrade_signal_os';
import { op_daytrade_signal_pm } from '~/trades/indicators/sungop/op_daytrade_signal_pm';
import { op_daytrade_signal_stk } from '~/trades/indicators/sungop/op_daytrade_signal_stk';
import { op_orb } from '~/trades/indicators/sungop/op_orb';
import { op_signal } from '~/trades/indicators/sungop/op_signal';
import { op_trend } from '~/trades/indicators/sungop/op_trend';
export const sungop_strategiesGroup = {
    main: [
        {
            displayName: '當沖',
            symbol: 'TX-1',
            interval: '5',
            indicators: [op_orb, op_trend, op_daytrade_signal],
            panesRatio: 70,
        },
        {
            displayName: '波段',
            symbol: 'TX-1',
            interval: '5',
            indicators: [op_orb, op_trend, op_signal],
            panesRatio: 70,
        },
        {
            displayName: '夜沖',
            symbol: 'TX-1',
            interval: '5',
            indicators: [op_orb, op_trend, op_daytrade_signal_pm],
            panesRatio: 70,
        },
        {
            displayName: '海期',
            symbol: 'YM-1',
            interval: '5',
            indicators: [op_orb, op_daytrade_signal_os],
            panesRatio: 70,
        },
        {
            displayName: '股期',
            symbol: 'QFF-1',
            interval: '5',
            indicators: [op_orb, op_daytrade_signal_stk],
            panesRatio: 70,
        },
    ],
};
export const sungop_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[
            op_orb,
            op_trend,
            op_daytrade_signal,
            op_daytrade_signal_pm,
            op_daytrade_signal_os,
            op_daytrade_signal_stk,
        ],
    ];
    store.charting.initStrategy({
        configs: [...sungop_strategiesGroup.main],
    });
};
